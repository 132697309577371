html {
  box-sizing: border-box;
  overflow: auto;
  scroll-behavior: smooth;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  position: relative;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: 'Rubik';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(rgb(33, 37, 41), rgb(51, 51, 51));
  min-height: 100vh;
  overflow: hidden;
}

:root{
  --blob: "m 238.11131,151.0746 c -0.99979,21.59283 -18.75134,29.80493 -32.39323,46.90857 -22.25535,27.90285 -21.87549,27.29364 -59.98747,23.45429 -21.73642,-2.18971 -40.31946,10.8376 -65.686268,11.72714 C 55.593554,234.02202 37.453244,226.18416 25.45575,206.63892 13.458257,187.09368 8.0593849,167.54844 9.2591339,148.0032 10.458884,128.45796 8.0593849,107.60971 2.0606381,85.458436 -3.9381084,63.307167 3.1604086,46.367961 23.356189,34.640817 43.55197,22.913674 64.147665,13.792562 85.143277,7.277483 106.13889,0.76240299 128.43423,-1.3782661 152.0293,0.85547549 175.62437,3.0892172 193.52063,13.69949 205.71808,32.686294 c 12.19745,18.986801 20.89563,38.066677 26.09455,57.239626 5.19891,19.17295 7.29847,39.55584 6.29868,61.14868 z";
  --blobDesktop: "m 389.5753,253.06333 c -1.63575,36.1699 -30.67917,49.9259 -52.99874,78.57601 -36.41212,46.73975 -35.79062,45.71927 -98.14585,39.28801 -35.56309,-3.66796 -65.96691,18.15394 -107.4697,19.644 C 90.956939,392.0076 61.277471,378.8785 41.648301,346.13849 22.019133,313.39848 13.186007,280.65847 15.148923,247.91846 17.111842,215.17846 13.186007,180.25579 3.371422,143.15045 -6.4431623,106.04511 5.1707629,77.670441 38.213198,58.026436 71.255633,38.382433 104.95237,23.103763 139.30341,12.190428 173.65446,1.2770925 210.13199,-2.3087178 248.73603,1.4329972 c 38.60403,3.7417152 67.88421,21.5148608 87.84053,53.3194398 19.95631,31.804573 34.18746,63.765053 42.69344,95.881443 8.50596,32.11638 11.94107,66.25954 10.3053,102.42945 z";
  /* --blobDesktop: "m 342.97099,225.36269 c -1.44006,32.21069 -27.00906,44.46094 -46.65858,69.97497 -32.05619,41.62356 -31.50904,40.71478 -86.40481,34.9875 -31.30873,-3.26646 -58.07538,16.16678 -94.61326,17.49374 C 80.075897,349.09794 53.946939,337.40596 36.665977,308.24972 19.385017,279.09347 11.608585,249.93723 13.33668,220.78098 15.064778,191.62474 11.608585,160.52476 2.9681039,127.48101 -5.6723765,94.437274 4.5521923,69.168533 33.641811,51.674786 62.731429,34.181042 92.397076,20.574795 122.63875,10.856048 c 30.24169,-9.7187477 62.35547,-12.9120505 96.34136,-9.5799085 33.98589,3.3321421 59.76333,19.1598155 77.3323,47.4830245 17.56897,28.323202 30.09767,56.785246 37.58609,85.386136 7.48841,28.60088 10.51258,59.00668 9.07249,91.217"; */
  --headline: 2rem;
  --headlineSmall: 1.5rem;
  --menu: 1.2rem;
  --text: 1rem;
}

@media(min-width: 1025px) {
  :root{
    --headline: 2.7rem;
    --headlineMedium: 2.35rem;
    --text: 1.44rem;
    --textSmall: 1.28rem;
  }
  
}


h1, h2, p{
  color: white;
}

